import React from "react"
// import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import StarIcon from "../../assets/images/star-icon.png"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    strapiTeamStyle2 {
      title
      shortDesc
      longDesc
      teamCard {
        id
        name
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        designation
        socialLinks {
          id
          icon
          link
        }
      }
    }
  }
`

const TeamStyleOne = () => {
  const data = useStaticQuery(query)
  const {
    strapiTeamStyle2: { title, shortDesc, longDesc, teamCard },
  } = data

  return (
    <section className="scientist-area bg-color pt-100 pb-70">
      <div className="container">
        <div className="section-title pt-40">
          <span className="sub-title">
            <img src={StarIcon} alt="testimonial" />
            {title}
          </span>
          <h2>{shortDesc}</h2>
          <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
        </div>
        <div className="row">
          {teamCard.map(card => {
            // const { socialLinks } = card
            return (
              <div className="col-lg-3 col-sm-6" key={card.id}>
                <div className="single-scientist-box">
                  <div className="image">
                    <img
                      src={card?.image?.localFile?.childImageSharp.original.src}
                      alt="Team"
                    />
                  </div>
                  <div className="content">
                    <h3>{card.name}</h3>
                    <span>{card.designation}</span>
                    {/* <ul className="social">
                      {socialLinks &&
                        socialLinks.map(links => {
                          const { link, icon } = links
                          return (
                            <li key={links.id}>
                              <Link to={link} className="d-block">
                                <i className={icon}></i>
                              </Link>
                            </li>
                          )
                        })}
                    </ul> */}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default TeamStyleOne
